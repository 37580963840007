import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['source']

  async copy(event) {
    var elements = this.sourceTargets.map(el => el.innerText);
    var text = elements.join(' \n')
    // navigator.clipboard.writeText(text)
    if (navigator.clipboard && navigator.clipboard.write) {
      navigator.clipboard.write([
        new ClipboardItem({
          "text/plain": Promise.resolve(new Blob([text], { type: 'text/plain' }))
        })
      ])
      var current = event.currentTarget
      var successText = current.dataset.success
      if (successText) {
        current.innerText = successText
        setTimeout(() => {
          current.innerText = text
        }, 3000)
      }
    } else {
      console.log('Clipboard API not available')
    }
  }
}
